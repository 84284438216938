/* eslint-disable */
// Disable site in iframe
window.isIfr = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

if (isIfr()) {
  setTimeout(() => {
    document.body.innerHTML = '';
  }, 0);
}

/* =============================
*  NEW CALC INIT
================================ */
// SHORT CALC
$.getScript('/assets/js/calc.js', function () {
    var opts = {
        deafault: {
            deadlinesDefault: [110063, 110099, 110147, 110201, 110249],
            academicLevelDefault: 1,
            paperTypesDefault: 1,
            pagesDefault: 1
        }
    };

	if ($('[data-calc-short]').length > 0) {
		new Calc({
            ID: '22',
            calculatorType: 'ShortCalculator',
            format: 'json',
            options: opts
        });
	}

    if ($('[data-calc-mini]').length > 0) {
        new Calc({
            ID: '22',
            calculatorType: 'DefaultCalculator',
            format: 'html',
            options: opts
        });
    }
});
/* =============================
*  end NEW CALC INIT
================================ */



/**
 * Description: JS for ordercustompaper.com
 * Version: 1.0.0
 * Last update: 2015/08/01
 * Author: UVOCorp
 *
 * Summary:
 *
 *  0. matchMedia() polyfill
 *    - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license
 *  1. Respond.js
 *    - min/max-width media query polyfill.
 *  2. BxSlider v4.0 - Fully loaded, responsive content slider
 *    - Copyright 2012, Steven Wanderski, http://bxslider.com
 *  3. Custom JS for ordercustompaper.com
 *
 */


/* ==========================================================================
   2. Custom JS for ordercustompaper.com
   ========================================================================== */

$(function() {
    //count pages
    $.fn.counts = function(options) {
        //defaultOptions
        options = $.extend({
            minusClass: 'pageskol-left',
            minimum: 1
        }, options);
        var inputCol = $(this);
        if ($(inputCol).val() <= options.minimum) {
            $('button[class=' + options.minusClass + ']').css({
                'opacity': '0.5',
                'cursor': 'default'
            });
        } else {
            $('button[class=' + options.minusClass + ']').css({
                'opacity': '1',
                'cursor': 'pointer'
            });
        }
        $(inputCol).parent().find('span').click(function() {
            if ($(this).hasClass(options.minusClass)) {
                if ($(inputCol).val() >= options.minimum) {
                    var count = parseInt($(inputCol).val()) - 1;
                    count = count < options.minimum ? options.minimum : count;
                } else {
                    $(inputCol).val(options.minimum - 1);
                    var count = parseInt($(inputCol).val()) - 1;
                    count = count < options.minimum ? options.minimum : count;
                }
                if ($(inputCol).val() <= (options.minimum + 1)) {
                    $('button[class=' + options.minusClass + ']').css({
                        'opacity': '0.5',
                        'cursor': 'default'
                    });
                }
            } else {
                if ($(inputCol).val() >= options.minimum) {
                    var count = parseInt($(inputCol).val()) + 1;
                } else {
                    $(inputCol).val(options.minimum - 1);
                    var count = parseInt($(inputCol).val()) + 1;
                }
                if ($(inputCol).val() > (options.minimum - 1)) {
                    $('button[class=' + options.minusClass + ']').css({
                        'opacity': '1',
                        'cursor': 'pointer'
                    })
                }
            }
            $(inputCol).val(count);
            $(inputCol).change();
            $(this).parent().next().find('span').html(275 * count);
            return false;
        });
        $(this).keydown(function(e) {
            if (e.which != 8 && e.which != 0 && (e.which < 37 || e.which > 57) && e
                .which != 96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
                100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
                104 && e.which != 105) {
                return false;
            }
            var key, keyChar;
            if (!e) var e = window.e;
            if (e.keyCode) key = e.keyCode;
            else if (e.which) key = e.which;
            if (key == 38 || key == 39 || (e.which < 48 || e.which > 57) && e.which !=
                96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
                100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
                104 && e.which != 105) {
                if (key == 40 || key == 37) {
                    if ($(this).val() >= options.minimum) {
                        var count = parseInt($(this).val()) - 1;
                        count = count < options.minimum ? options.minimum : count;
                    } else {
                        $(this).val(options.minimum - 1);
                        keyChar = String.fromCharCode(key);
                        var count = parseInt($(this).val()) + 1;
                    }
                } else {
                    if ($(this).val() >= options.minimum) {
                        var count = parseInt($(this).val()) + 1;
                    } else {
                        $(this).val(options.minimum - 1);
                        var count = parseInt($(this).val()) + 1;
                    }
                }
                $(this).val(count);
                $(this).parent().next().find('span').html(275 * count);
                if ($(inputCol).val() <= (options.minimum)) {
                    $('button[class=' + options.minusClass + ']').css({
                        'opacity': '0.5',
                        'cursor': 'default'
                    });
                } else {
                    $('button[class=' + options.minusClass + ']').css({
                        'opacity': '1',
                        'cursor': 'pointer'
                    })
                }
            }
        });
    }
    //counts
    $('#input-kol').counts();
    //toplink click
    $('.totop-link').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
    //toggle topmenu
    $('.toggle-menu').click(function() {
        $(this).toggleClass('active');
        if ($(this).next().css('display') == 'none') {
            $(this).next().show();
        } else {
            $(this).next().hide();
        }
        return false;
    });
    if ($('.topmenu-line').length > 0) {
        var tmenu = $('.topmenu-line'),
            tmenuTop = $('.topmenu-line').offset().top;
    }
    $(window).scroll(function() {
        //fixed menu
        var scrlTopPos = $(window).scrollTop(),
            windowWidth = parseInt($(window).width()) + 17;
        if (windowWidth >= 968) {
            if (scrlTopPos <= tmenuTop) {
                $(tmenu).removeClass('fixed');
            } else if (scrlTopPos > tmenuTop) {
                $(tmenu).addClass('fixed');
            }
        }
        //show to-top link
        if ($(window).scrollTop() >= 200) {
            $('.totop-link').fadeIn();
        } else {
            $('.totop-link').fadeOut();
        }
    });

    //password reminder
    //for inquiry page
    $('.show-hide').find('.hidden').hide();
    $('#inq-done-more').click(function() {
        if (!$(this).hasClass('less')) {
            $(this).parents('.show-hide').find('.hidden').slideDown(500);
            $(this).text('Read less').addClass('less');
        } else {
            $(this).parents('.show-hide').find('.hidden').slideUp(500);
            $(this).text('Read more').removeClass('less');
        }
    });
    $('.plagcheckc aside#rightplagpanel').clone().appendTo('.plagcheckc').addClass(
        'rgt-spec-plag').attr('id', 'rightplagpanel_2');

});

jQuery(function( $ ) {
    $('.bxslider').bxSlider({
        mode: "fade",
        speed: 3000,
        auto: true,
        pager: false,
        controls: false,
        autoControls: false
    });

    $('.testimonials-carousel').owlCarousel({
        loop: true,
        margin: 10,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            }
        }
    });

});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '22',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function toggleListInit() {
    function toggle_list() {
        $('.toggle-list .toggle-content').addClass('hide');
        $(document).on('click', '.toggle-list .toggle-link', function(){
            if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
            $(this).parent().toggleClass('active');
            $(this).next().toggleClass('hide');
            return false;
        });
    }

    if (document.querySelector('.faq__component')) {
        window.addEventListener('DOMContentLoaded', toggle_list);
    }

    if (document.querySelector('[data-page="frequently_asked_questions"]') || document.querySelector('[data-page="money_back_guarantee"]')){
      window.addEventListener('contentRendered', toggle_list);
    }
  })();
/* eslint-enable */
